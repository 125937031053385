import { readableColor } from 'polished'
import React from 'react'
import { AppTheme } from '../../../theme'
import { Typography, TeaserButton } from '../../atoms'
import { TeaserProps } from './static'
import { TeaserContainer, TeaserBackground } from './global_components'

export const TeaserMS: React.FC<TeaserProps> = ({ productName, title, onStart, fontColor }) => {
  const ButtonTextColor = readableColor(
    AppTheme.colors.primary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  return (
    <TeaserContainer className='teaser_ms'>
      <TeaserBackground
        svgPath={`${process.env.HOST_URL}:${process.env.app_port}/public/background.svg`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            padding: '18px',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}>
          <Typography fontSize='16px' color={fontColor} fontWeight={AppTheme.fontweight.regular}>
            {productName}
          </Typography>

          <Typography
            color={fontColor}
            fontSize='25px'
            fontWeight={AppTheme.fontweight.medium}
            lineHeight='2rem'>
            {title}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: 'auto' }}>
            <TeaserButton bgColor={AppTheme.colors.primary} onClick={onStart}>
              <Typography
                fontWeight={AppTheme.fontweight.medium}
                fontSize='15px'
                color={ButtonTextColor}>
                STARTEN
              </Typography>
            </TeaserButton>
          </div>
        </div>
      </TeaserBackground>
    </TeaserContainer>
  )
}
