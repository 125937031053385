import { readableColor } from 'polished'
import React from 'react'
import { AppTheme } from '../../theme'
import { TeaserLL, TeaserML, TeaserLS, TeaserMS } from './components'

interface TeaserProps {
  showPlz: boolean
  productName: string
  title: string
  subtitle?: string
  size: 'ml' | 'ms' | 'll' | 'ls' | ''
  customFontColor?: string
  bgImage?: string
  openApp(): void
}

export const Teaser: React.FC<TeaserProps> = ({
  size,
  productName,
  title,
  subtitle,
  openApp,
  customFontColor
}) => {
  const TeaserFontColor = readableColor(
    AppTheme.colors.secondary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  const FontColor = customFontColor || TeaserFontColor

  return (
    <>
      {size === 'll' && (
        <TeaserLL
          productName={productName}
          title={title}
          onStart={openApp}
          intro={subtitle}
          fontColor={FontColor}
        />
      )}

      {size === 'ml' && (
        <TeaserML productName={productName} title={title} onStart={openApp} fontColor={FontColor} />
      )}

      {size === 'ls' && (
        <TeaserLS
          productName={productName}
          title={title}
          onStart={openApp}
          intro={subtitle}
          fontColor={FontColor}
        />
      )}

      {size === 'ms' && (
        <TeaserMS productName={productName} title={title} onStart={openApp} fontColor={FontColor} />
      )}
    </>
  )
}

export default Teaser
