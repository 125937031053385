export const getGeneratedPageURL = ({ html, css, js }: any) => {
  const getBlobURL = (code: string, type: string) => {
    const blob = new Blob([code], { type })
    return URL.createObjectURL(blob)
  }
  const cssURL = getBlobURL(css, 'text/css')
  const jsURL = getBlobURL(js, 'text/javascript')

  const source = `
  <html>
    <head>
      ${css && `<link rel="stylesheet" type="text/css" href="${cssURL}" />`}
      ${js && `<script src="${jsURL}"></script>`}
    </head>
    <body>
      ${html || ''}
    </body>
  </html>
  `
  return getBlobURL(source, 'text/html')
}

export const modalIFrameDocument = {
  html: `
    <div class="modal_iframe">
      <div class="modal_iframe_quit" onClick="closeIFrame()"}>&times;</div>
    </div>`,
  css: `
    .modal_iframe {
      position: absolute;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.7);
      z-index: 99999;
    }
    .modal_iframe_quit {
      color: white;
      font-size: 2.8rem;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 0;
    }
  `,
  js: `
    function closeIFrame() {
      parent.postMessage('close_app', '*')
    }
  `
}

export const modalIFrameDocumentMobile = {
  html: `
      <div class="modal_iframe_quit" onClick="closeIFrame()"}>&times;</div>
    `,
  css: `

    .modal_iframe_quit {
      color: white;
      font-size: 2.8rem;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: -5px;
    }
  `,
  js: `
    function closeIFrame() {
      parent.postMessage('close_app', '*')
    }
  `
}

export default getGeneratedPageURL
