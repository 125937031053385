import { getBffURL } from '../../utils'

export function renderAppIFrame() {
  const iFrame: any = document.createElement('iframe')
  iFrame.id = 'app_container_iframe'

  if (window.innerWidth < 968) {
    // Mobile
    iFrame.style.position = 'fixed'
    iFrame.style.right = '0px'
    iFrame.style.top = '0px'
    iFrame.style.left = '0px'
    iFrame.style.bottom = '0px'
    iFrame.style.width = '100%'
    iFrame.style.height = '100%'
    iFrame.style.border = 'none'
    iFrame.style.zIndex = '10000'
    iFrame.style.overflow = 'hidden'
  } else {
    // Laptop
    iFrame.style.position = 'fixed'
    iFrame.style.right = '45px'
    iFrame.style.top = '50px'
    iFrame.style.left = '45px'
    iFrame.style.bottom = '40px'
    iFrame.style.width = 'calc(100% - 90px)'
    iFrame.style.height = 'calc(100% - 90px)'
    iFrame.style.border = 'none'
    iFrame.style.zIndex = '100000'
    iFrame.style.overflow = 'hidden'
  }

  const body = window.parent.document.getElementsByTagName('body')[0]

  const eventMethod = !!window.addEventListener ? 'addEventListener' : 'attachEvent'
  const eventer = window[`${eventMethod}`]
  const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message'

  eventer(messageEvent, (e: any) => {
    if (e.data === 'close_app' || e.message === 'close_app') {
      body.removeChild(iFrame)
    }
  })

  iFrame.src = `${getBffURL}/index.html`
  body.appendChild(iFrame)
}

const methodNameForIFrame = `render_app_iframe`
;(window as any)[methodNameForIFrame] = renderAppIFrame
