import { readableColor } from 'polished'
import React from 'react'
import styled from 'styled-components'
import { TeaserProps } from './static'
import { AppTheme } from '../../../theme'
import { getBffURL } from '../../../utils'
import { TeaserButton, Typography } from '../../atoms'
import {
  TeaserContainer,
  TeaserBackground,
  SVGContainer,
  TeaserHeader,
  TeaserFooter
} from './global_components'

export const TeaserLL: React.FC<TeaserProps> = ({
  productName,
  title,
  intro,
  onStart,
  fontColor
}) => {
  const ButtonTextColor = readableColor(
    AppTheme.colors.primary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  return (
    <TeaserContainer className='teaser_ll'>
      <TeaserBackground
        svgPath={`${process.env.HOST_URL}:${process.env.app_port}/public/background.svg`}>
        <TeaserHeader className='teaser_header'>
          <Typography fontSize='20px' color={fontColor} fontWeight={AppTheme.fontweight.regular}>
            {productName}
          </Typography>
        </TeaserHeader>

        <ContentContainer>
          <TextContent>
            <Typography
              color={fontColor}
              fontSize='40px'
              fontWeight={AppTheme.fontweight.medium}
              lineHeight='3.2rem'>
              {title}
            </Typography>
            <Typography
              color={fontColor}
              fontSize='18px'
              fontWeight={AppTheme.fontweight.medium}
              lineHeight='1.8rem'>
              {intro}
            </Typography>
            <ButtonContainer>
              <TeaserButton bgColor={AppTheme.colors.primary} onClick={onStart}>
                <Typography
                  fontWeight={AppTheme.fontweight.medium}
                  fontSize='18px'
                  color={ButtonTextColor}>
                  STARTEN
                </Typography>
              </TeaserButton>
            </ButtonContainer>
          </TextContent>
          <SVGContainer className='svg_container' svgPath={`${getBffURL}/public/teaser_haus.svg`} />
        </ContentContainer>
        <TeaserFooter className='teaser_footer' bg={AppTheme.colors.primary} />
      </TeaserBackground>
    </TeaserContainer>
  )
}

const ButtonContainer = styled.div`
  padding: 25px 0px;
`

const ContentContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`
const TextContent = styled.div`
  display: flex;
  padding: 0px 10px 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 60%;
  height: 100%;
  z-index: 10;
  user-select: none;
  max-width: 700px;
`
