import React from 'react'
import { TeaserProps } from './static'
import { readableColor } from 'polished'
import { AppTheme } from '../../../theme'
import { TeaserContainer, TeaserBackground } from './global_components'
import { TeaserButton, Typography } from '../../atoms'

export const TeaserML: React.FC<TeaserProps> = ({ productName, title, onStart, fontColor }) => {
  const ButtonTextColor = readableColor(
    AppTheme.colors.primary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  return (
    <TeaserContainer className='teaser_ml'>
      <TeaserBackground
        svgPath={`${process.env.HOST_URL}:${process.env.app_port}/public/background.svg`}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            padding: '18px 36px',
            justifyContent: 'space-between'
          }}>
          <div style={{ display: 'grid' }}>
            <Typography fontSize='20px' color={fontColor} fontWeight={AppTheme.fontweight.regular}>
              {productName}
            </Typography>
            <div>
              <Typography
                color={fontColor}
                fontSize='42px'
                fontWeight={AppTheme.fontweight.medium}
                lineHeight='3.4rem'>
                {title}
              </Typography>
            </div>
          </div>
          <div style={{ alignSelf: 'center' }}>
            <TeaserButton bgColor={AppTheme.colors.primary} onClick={onStart}>
              <Typography
                fontWeight={AppTheme.fontweight.regular}
                fontSize='18px'
                color={ButtonTextColor}>
                STARTEN
              </Typography>
            </TeaserButton>
          </div>
        </div>
      </TeaserBackground>
    </TeaserContainer>
  )
}
