import React from 'react'
import { SVGContainerProps, TeaserProps } from './static'
import { readableColor } from 'polished'
import { AppTheme } from '../../../theme'
import { TeaserContainer, TeaserBackground } from './global_components'
import { TeaserButton, Typography } from '../../atoms'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { getBffURL } from '../../../utils'

export const TeaserLS: React.FC<TeaserProps> = ({
  productName,
  title,
  intro,
  onStart,
  fontColor
}) => {
  const ButtonTextColor = readableColor(
    AppTheme.colors.primary,
    AppTheme.colors.black,
    AppTheme.colors.white
  )

  return (
    <TeaserContainer className='teaser_ls'>
      <TeaserBackground
        svgPath={`${process.env.HOST_URL}:${process.env.app_port}/public/background.svg`}>
        <ContentContainer>
          <Typography fontSize='16px' color={fontColor} fontWeight={AppTheme.fontweight.regular}>
            {productName}
          </Typography>

          <Typography
            color={fontColor}
            fontSize='25px'
            fontWeight={AppTheme.fontweight.medium}
            lineHeight='2rem'>
            {title}
          </Typography>
          <Typography
            color={fontColor}
            fontSize='15px'
            fontWeight={AppTheme.fontweight.semiBold}
            lineHeight='1.4rem'>
            {intro}
          </Typography>
        </ContentContainer>
        <SVGContainer className='svg_container' svgPath={`${getBffURL}/public/teaser_haus.svg`} />
        <TeaserFooter className='teaser_footer' bg={AppTheme.colors.primary}>
          <TeaserButton bgColor={AppTheme.colors.primary} onClick={onStart}>
            <Typography
              fontWeight={AppTheme.fontweight.medium}
              fontSize='15px'
              color={ButtonTextColor}>
              STARTEN
            </Typography>
          </TeaserButton>
        </TeaserFooter>
      </TeaserBackground>
    </TeaserContainer>
  )
}

const ContentContainer = styled.div`
  display: flex;
  padding: 0px 15px;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  height: 45%;
  z-index: 10;
  user-select: none;
`

const SVGContainer = styled.div<SVGContainerProps>`
  display: block;
  position: absolute;
  bottom: 113px;
  height: 100%;
  width: 90%;
  margin: auto 5%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 100%;
`

const TeaserFooter = styled.div<ColorProps>`
  width: 100%;
  height: 114px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0px;
  z-index: 5;

  ${color}
`
