import { keyframes, createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`
  div #teaserAutoEH {
    font-family: ${(props) => props.theme.fontFamily};
    *, *:before, *:after {
      box-sizing: border-box;
    }
  }

  .freeze {
    overflow: hidden;
  }

  .collapse-css-transition {
    transition: height 280ms cubic-bezier(0.4, 0, 0.2, 1);
  }

  .react-confirm-alert-overlay {
    z-index:10000!important;
  }

  .react-confirm-alert-blur {
    filter: url(#gaussian-blur);
    filter: blur(2px);
    -webkit-filter: blur(2px);
  }
`

export const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const FadeOut = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    visibility: hidden;
  }
`
