import { lighten } from 'polished'
import styled from 'styled-components'
import { color, ColorProps } from 'styled-system'
import { TeaserStylesProps, SVGContainerProps } from './static'

export const TeaserContainer = styled.div<TeaserStylesProps>`
  height: 100%;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  z-index: 1;
  background-color: ${(props) => props.theme.colors.secondary};
  background-image: linear-gradient(
    to right,
    ${(props) => lighten(0.1, props.theme.colors.secondary)},
    transparent
  );
`

export const SVGContainer = styled.div<SVGContainerProps>`
  display: block;
  position: absolute;
  bottom: 39px;
  right: 0;
  height: 100%;
  width: 50%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: right;
  background-position-y: bottom;
`

export const TeaserBackground = styled.div<SVGContainerProps>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.svgPath});
  background-repeat: no-repeat;
  background-size: cover;
`
export const TeaserHeader = styled.div`
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  padding: 25px 40px;
`

export const TeaserFooter = styled.div<ColorProps>`
  width: 100%;
  height: 40px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 5;

  ${color}
`
