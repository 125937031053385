/* eslint-disable react/display-name */
import ReactDOM from 'react-dom'
import React, { useRef, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import Teaser from './teaser'
import { AppTheme } from '../../theme'
import { GlobalStyles } from './styles'
import { StoreProvider, useStore } from '../../context'
import { renderModalIFrame } from './modal_iframe'
import { renderAppIFrame } from './app_iframe'
import { useTeaserSize } from './utils/useTeaserSize'
import { observer } from 'mobx-react-lite'

export interface TeaserProps {
  showPlz: boolean
}

export const AppWithTeaser: React.FC<TeaserProps> = observer(({ showPlz }) => {
  const { appStore, contentStore } = useStore()

  const outerRef = useRef<HTMLDivElement>(null)
  const { teaserSize } = useTeaserSize(outerRef)

  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    contentStore.getAllContents().then(() => {
      appStore.getCiSettingsAndMapToTheme(AppTheme).then(() => {
        setIsLoading(false)
      })
    })
  }, [contentStore, appStore])

  const handleOpenApp = () => {
    renderModalIFrame()
    renderAppIFrame()
  }

  const Subtitle = {
    ll: appStore?.CISettings?.settings_sw_teaser_sub_title_l,
    ls: appStore?.CISettings?.settings_sw_teaser_sub_title_m
  }

  const customFontColor = appStore?.CISettings?.settings_sw_teaser_font_color

  return (
    <ThemeProvider theme={AppTheme}>
      <StoreProvider>
        <div
          style={{ display: 'block', height: '100%', width: '100%', boxSizing: 'border-box' }}
          id='teaser__outer-container'
          ref={outerRef}>
          {isLoading ? (
            <>Loading</>
          ) : (
            <Teaser
              openApp={handleOpenApp}
              showPlz={showPlz}
              size={teaserSize}
              productName='Energiehaus'
              subtitle={Subtitle[teaserSize]}
              title={
                teaserSize === 'ls' || teaserSize === 'ms'
                  ? appStore?.CISettings.settings_sw_teaser_title_s
                  : appStore?.CISettings.settings_sw_teaser_title_l
              }
              customFontColor={customFontColor}
            />
          )}
          <GlobalStyles />
        </div>
      </StoreProvider>
    </ThemeProvider>
  )
})

Teaser.displayName = 'Teaser'

export default AppWithTeaser

function renderTeaser(rootEl: HTMLElement, showPlz: boolean) {
  ReactDOM.render(<AppWithTeaser showPlz={showPlz} />, rootEl)
}

const methodName = `render_teaser_m_eh`
;(window as any)[methodName] = renderTeaser
